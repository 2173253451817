<template>
  <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="1080"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title>Browse Events</v-card-title>

    <v-card-text>
      <div>
        We know things are a little uncertain right now, but we think it helps to have something amazing to look forward to in 2021!

        That's why at our racecourse you can buy with confidence, safe in the knowledge that you won't lose out due to any Covid restrictions.

        So when you book any event at our racecourse, should we enter another lockdown or if you need to self-isolate due to Coronavirus, our team will be happy to transfer your booking to another event, or offer you a full refund.

        More fixtures will be available to book soon... watch this space for some new exciting events!</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Please select an event</v-card-title>

    <v-card-text>
        <v-simple-table >
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in desserts"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td align="right"><v-btn color="success">Buy Ticket</v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>    
  </v-card-text>

   <!-- <v-card-actions>
      <v-btn
        color="deep-purple lighten-2"
        text
        @click="reserve"
      >
        Reserve
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
data () {
      return {
        desserts: [
          {
            name: 'Non tempor amet Lorem Lorem proident. Labore cillum pariatur nulla exercitation voluptate veniam consequat et incididunt quis. Cillum pariatur esse voluptate sunt exercitation irure in culpa elit eu aliqua ipsum.',
            calories: 159,
          },
          {
            name: 'Non commodo sunt et ex amet aliqua consectetur minim nisi officia excepteur incididunt. Magna fugiat voluptate ullamco sint. Nulla excepteur commodo magna officia ut aliqua quis eu consectetur irure deserunt nisi pariatur. Anim nostrud Lorem pariatur non nulla quis ullamco quis sint ipsum fugiat enim. Sint culpa velit dolore proident ex. Laborum duis velit commodo nostrud laboris fugiat. Commodo est id anim incididunt tempor dolor tempor do quis Lorem irure incididunt ullamco.',
            calories: 237,
          },
          {
            name: 'Aliqua culpa ipsum amet enim eiusmod eiusmod.',
            calories: 262,
          },
          {
            name: 'Sunt duis minim ex cillum velit laborum culpa. Quis ea commodo ex aliquip commodo occaecat nisi voluptate eu consequat Lorem dolor. Labore eu sit ad pariatur elit cupidatat deserunt magna deserunt eiusmod cillum occaecat tempor.',
            calories: 305,
          },
          {
            name: 'Cupidatat aliquip consequat ipsum eiusmod et cupidatat incididunt tempor ea aliquip non dolor. Ex qui esse velit dolor nostrud sit. Occaecat minim sunt pariatur ullamco laboris nostrud labore ullamco incididunt cupidatat enim. Magna sunt dolore in occaecat aute. Adipisicing nisi excepteur eu esse reprehenderit. In consectetur ad deserunt anim nulla dolore eu cupidatat. Labore do deserunt cillum duis fugiat ullamco.',
            calories: 356,
          },
          {
            name: 'Sit occaecat occaecat commodo ex enim nulla laborum duis irure et duis ut officia minim. Quis veniam incididunt commodo nulla nisi. Dolor veniam incididunt aliquip ut esse laboris anim proident.',
            calories: 375,
          },
          {
            name: 'Reprehenderit exercitation Lorem reprehenderit mollit ullamco aute ad elit pariatur culpa Lorem excepteur. Irure minim culpa incididunt non cupidatat sunt quis laboris. Ut proident dolor labore ex in duis esse ea exercitation nisi est consequat reprehenderit do. Occaecat ad esse cupidatat commodo consectetur tempor sunt reprehenderit non consequat laborum ipsum quis. Tempor tempor ipsum dolore quis do dolore amet sunt velit est culpa do.',
            calories: 392,
          },
          {
            name: 'Culpa ea aliquip ea laboris. Anim tempor deserunt ad do minim fugiat est esse quis id magna. Id labore cupidatat sunt non id aliqua voluptate est qui consequat. Culpa magna Lorem aliquip mollit qui laborum eu sint fugiat sint sit. Ullamco sit duis mollit minim. Occaecat veniam laborum elit laborum non occaecat voluptate quis irure cupidatat adipisicing qui anim.',
            calories: 408,
          },
          {
            name: 'Duis Lorem magna minim esse exercitation ut enim et ipsum adipisicing. Nulla eiusmod aliquip eu proident. Irure enim culpa irure ut nulla ex ex et anim minim tempor dolor nostrud labore. Duis aliqua labore elit magna esse est. Consequat enim anim aliqua enim dolor voluptate nisi veniam pariatur cupidatat in. Dolor qui ut nisi nostrud nulla ipsum incididunt excepteur elit dolor aliquip qui eiusmod pariatur. Occaecat voluptate ullamco sit id aute minim enim eiusmod exercitation esse.',
            calories: 452,
          },
          {
            name: 'Ad commodo labore consequat exercitation anim dolor minim ipsum eiusmod sit laboris. Dolore aute culpa ex nulla aliquip dolor elit officia in irure ad. Ut enim dolore ipsum ad labore commodo veniam sint id anim qui elit. Ipsum sit enim officia ea aliqua fugiat commodo ad occaecat aliqua quis enim magna adipisicing. Ea occaecat laboris eiusmod sit amet dolore id. Sunt cillum sunt consectetur dolore mollit pariatur. Sunt laboris eiusmod sint aute ad ex anim enim exercitation pariatur nulla ex.',
            calories: 518,
          },
        ],
      }
    },

}
</script>
