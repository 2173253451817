<template>
  <v-app>  
    <NavBar :drawer=true @finished='customEvent' :agoy='customEvent'/> 
    <MainMenu :drawer='drawer' ref='MainMenu'  />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/Navbar'
import MainMenu from './components/MainMenu'

export default {
  name: 'App',
  components : {
    NavBar,
    MainMenu
    },

  data()  {
    return {
      drawer: false 
    }
  },
  methods : {
    customEvent(){
      this.drawer = !this.drawer 
    }
  }
};
</script>
