<template>
  <div>
    <v-app-bar      
      dense
      height="100"
      app 
    >
      <v-app-bar-nav-icon @click='agoy'></v-app-bar-nav-icon>
      <v-toolbar-title><span class="text-h3">Super Cart</span></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>

// @ is an alias to /src

export default {

  name: 'Home',
  props: ['drawer','agoy'],
  data(){
    return {
      test: true 
    }
  },
  mounted(){
      document.title ='Cart Ticketing System'
  },
  methods: {
    nothing(){
      alert("nothing...")
    }
  }
}
</script>
