<template>
     <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      app
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>John Leider</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>


    </v-navigation-drawer>
</template>

<script>
import MainMenu from './MainMenu'

// @ is an alias to /src

export default {
  component: {
    MainMenu
  },
  props: ['drawer'],
  name: 'Home',

  data(){
      return {
           mini: true ,
      }
  },
  methods: {
    showDrawer(){
  
    },
    toggleDrawer(){
  
    }
  }

}
</script>